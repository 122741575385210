.itemDescription {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-weight: bold;
}

tr:not(:first-child) td.bordered {
  border-top: 1px solid #d8d8d8;
}

.itemSubDescription {
  font-weight: normal;
}
