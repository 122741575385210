.table {
  border-collapse: separate;
  border-spacing: 0;

  .group-row-item {
    display: table-row !important;
  }
}

.quantity-cell {
  text-align: right;
  vertical-align: top;
}

.header-row {
  tr {
    padding-top: 2.5px;
  }
  td {
    font-weight: bold;
  }
}

tr.group-row-item:last-child {
  td {
    padding-bottom: 1em;
  }
}

tr.top-double-bordered {
  margin-top: 5px;
  td {
    border-top: 3px double #6e6e6e;
  }
}

tr:not(:first-child).top-bordered td {
  border-top: 1px solid #d8d8d8;
}
