:global(.ui).modal-change-item {
  > :global(.header:not(.ui)) {
    font-size: 1rem;
    @media only screen and (min-width: 768px) {
      font-size: 1.4rem;
    }

    > :global(.ui.button) {
      padding: 0.4rem 1rem;
    }
  }
}

:global(.ui.list) > .selected {
  :global(.header) {
    color: #267d37;
  }
}

:global(.ui.list) > .item-modifier {
  display: flex;
  align-items: center;

  :global(.content) {
    flex-grow: 1;
  }

  .item-buttons {
    flex-grow: 0;
  }

  :global(.ui.buttons .or:before) {
    color: black;
  }
}

.basic-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .quantity-changer {
    flex-grow: 0;
  }

  .variant-selector {
    margin-top: 10px;
    flex-grow: 0;
    display: flex;
    align-items: center;

    :global(.header) {
      margin-bottom: 0;
      margin-right: 10px;
    }

    :global(.ui.horizontal.list) {
      display: flex;

      :global(.item) {
        display: flex;
        align-items: center;

        :global(.content > .description) {
          padding: 0.2em 0 !important;
        }
      }
    }
  }
}
