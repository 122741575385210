.selected {
  background-color: #ebebeb;
  font-weight: bold;
}

:global(.ui.list > .item .header).header {
  font-weight: normal;
}

:global(.ui.list > .item .header).header.selected {
  font-weight: bold;
}
