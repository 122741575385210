:global(.ui.cards) > a.visible-border,
:global(.ui.cards) > a.visible-border:hover {
  border: 1px solid rgb(71, 71, 71);
}

:global(.ui.cards).cards-dish {
  margin-left: -0.3em;
  margin-right: -0.3em;

  @media only screen and (min-width: 768px) {
    margin-left: -1em;
    margin-right: -1em;
  }

  .card-dish {
    margin-left: 0.3em;
    margin-right: 0.3em;
    @media only screen and (min-width: 768px) {
      margin-left: 1em;
      margin-right: 1em;
    }

    :global(.content) {
      padding: 0.3em 0.3em;

      @media only screen and (min-width: 768px) {
        padding: 0.5em 0.5em;
      }

      :global(.header + .description) {
        text-align: right;
        margin-top: 0;
      }

      :global(.description) {
        font-size: 0.7em;
        @media only screen and (min-width: 768px) {
          font-size: 1em;
        }
      }

      .no-image-header {
        font-size: 0.8em;
        margin-bottom: 0;
        @media only screen and (min-width: 768px) {
          font-size: 1em;
          margin-bottom: 0;
        }
      }
    }
  }
}
