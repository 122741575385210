.container {
  max-height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: flex-start;
}

.menu {
  order: 1;
  flex: 0 0 auto;
  min-height: 0px;
}

.order {
  order: 2;
  flex: 1;
  min-height: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
}

.actions {
  order: 3;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.actions__alert {
  font-size: 16px;
  border-radius: 5px;
  padding: 5px;
  text-align: left;
  background-color: #c0392b;
}

.actions__alert-text {
  color: white;
}

.discount-description {
  font-weight: bold;
}

.highlighted {
  color: green;
}

.order-header {
  display: flex;
  justify-content: space-between;
}

:global(.header) .clear-order-button i:global(.icon):hover {
  font-size: 1.2em;
  transition: 0.1s linear;
  cursor: pointer;
}

:global(.header) .clear-order-button i:global(.icon):active {
  font-size: 0.8em;
  cursor: pointer;
}
